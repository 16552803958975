export const AWAITING_START_STATE = 'AwaitingStart';

export const AWAITING_SPLIT_SEGMENT_STATE = 'AwaitingSplitSegment';

export const AWAITING_RUN_COMMUNICATION_STATE = 'AwaitingRunCommunication';

export const AWAITING_BEST_RESULT_STATE = 'AwaitingBestResult';

export const AWAITING_RUN_COMMUNICATION_REST_STATE =
  'AwaitingRunCommunicationRest';

export const FINISHED_STATE = 'Finished';

export const CANCELED_STATE = 'Canceled';

export const FAILED_STATE = 'Failed';

export const ABSTRACT_CREATED_STATE = 'Created';

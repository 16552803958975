import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { INSTANCE_CODE } from '@konnektu/tokens';
import { Observable } from 'rxjs';
import {
  ABTestStateDto,
  AbTestDto,
  CreateAbTestDto,
  GetAbTestsParameters,
  UpdateAbTestDto
} from './models';

@Injectable({ providedIn: 'root' })
export class AbTestService {
  private readonly http = inject(HttpClient);

  private readonly tenantCode = inject(INSTANCE_CODE);

  getAbTests(params?: GetAbTestsParameters): Observable<AbTestDto[]> {
    const httpParams: Record<string, number | string> = {
      sortBy: 'createdon',
      sortDirection: 'desc'
    };
    if (params?.limit) {
      httpParams['limit'] = params.limit;
    }
    if (params?.offset) {
      httpParams['offset'] = params.offset;
    }

    return this.http.get<AbTestDto[]>(
      `${this.tenantCode}/api/core/v1/communications/abtests`,
      { params: httpParams }
    );
  }

  getAbTest(id: number) {
    return this.http.get<AbTestDto>(
      `${this.tenantCode}/api/core/v1/communications/abtests/${id}`
    );
  }

  getAbTestRuns(abTestId: number) {
    return this.http.get<ABTestStateDto[]>(
      `${this.tenantCode}/api/core/v1/communications/abtests/${abTestId}/runs`
    );
  }

  checkAbTestName(name: string) {
    return this.http.get<boolean>(
      `${this.tenantCode}/api/core/v1/communications/abtests/checkname?name=${name}`
    );
  }

  createAbTest(dto: CreateAbTestDto) {
    return this.http.post<AbTestDto>(
      `${this.tenantCode}/api/core/v1/communications/abtests`,
      { ...dto }
    );
  }

  updateAbTest(id: number, dto: UpdateAbTestDto) {
    return this.http.put<AbTestDto>(
      `${this.tenantCode}/api/core/v1/communications/abtests/${id}`,
      { ...dto }
    );
  }

  deleteAbTest(id: number) {
    return this.http.delete<void>(
      `${this.tenantCode}/api/core/v1/communications/abtests/${id}`
    );
  }

  launchAbTest(id: number) {
    return this.http.post<void>(
      `${this.tenantCode}/api/core/v1/communications/abtests/runs`,
      { id }
    );
  }

  launchRest(abTestRunId: string, templateId: number) {
    return this.http.put<AbTestDto>(
      `${this.tenantCode}/api/core/v1/communications/abtests/runs/${abTestRunId}`,
      { templateId }
    );
  }
}
